import { CMSPageIds, CurrentUser, MappableObject, SettingPage } from 'types';

export type AdminLinks = {
  icon: string;
  name: string;
  link: string;
  checkPermissions: (user: CurrentUser | null) => boolean;
}[];

export const ADMIN_LINKS: AdminLinks = [
  {
    icon: '/icons/design/SquaresFour',
    name: 'Dashboard',
    link: '/admin-panel/dashboard',
    checkPermissions: (user) =>
      (!!user?.permissions && user.permissions.includes('dashboard_view')) || user?.role === 'super_admin',
  },
  {
    icon: '/icons/media/Headset',
    name: 'Support',
    link: '/admin-panel/support-tickets',
    checkPermissions: (user) =>
      (!!user?.permissions && user.permissions.includes('support_tickets_view')) || user?.role === 'super_admin',
  },
  {
    icon: '/icons/people/UserCircleGear',
    name: 'Admins',
    link: '/admin-panel/admins',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('admins_view'),
  },
  {
    icon: '/icons/people/Users',
    name: 'Users',
    link: '/admin-panel/customers',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('users_view'),
  },
  {
    icon: '/icons/office/Folder',
    name: 'Groups',
    link: '/admin-panel/customer-groups',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('customer_groups_view'),
  },
  {
    icon: '/icons/maps/Bicycle',
    name: 'Gear',
    link: '/admin-panel/items',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('gears_management_view'),
  },
  {
    icon: '/icons/miscellaneous/Qbp',
    name: 'QBP',
    link: '/admin-panel/qbp',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('qbp_catalog_view'),
  },
  {
    icon: '/icons/miscellaneous/LibertyMountain',
    name: 'LM',
    link: '/admin-panel/lm',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('lm_catalog_view'),
  },
  {
    icon: '/icons/people/UserRectangle',
    name: 'Ambassadors',
    link: '/admin-panel/ambassadors',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('ambassadors_view'),
  },
  {
    icon: '/icons/design/SquareHalf',
    name: 'Categories',
    link: '/admin-panel/categories',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('categories_view'),
  },
  {
    icon: '/icons/health/Heartbeat',
    name: 'Activities',
    link: '/admin-panel/activities',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('activities_view'),
  },
  {
    icon: '/icons/design/Swatches',
    name: 'Sizes',
    link: '/admin-panel/sizes',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('sizes_view'),
  },
  {
    icon: '/icons/design/PencilCircle',
    name: 'Brands',
    link: '/admin-panel/brands',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('brands_view'),
  },
  {
    icon: '/icons/commerce/Storefront',
    name: 'Markets',
    link: '/admin-panel/markets',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('markets_view'),
  },
  {
    icon: '/icons/design/PaintBucket',
    name: 'Colors',
    link: '/admin-panel/colors',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('colors_view'),
  },
  {
    icon: '/icons/commerce/Tag',
    name: 'Tags',
    link: '/admin-panel/tags',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('tags_view'),
  },
  {
    icon: '/icons/design/SquaresFour',
    name: 'Report Reasons',
    link: '/admin-panel/report-reasons',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('report_reasons_view'),
  },
  {
    icon: '/icons/commerce/Receipt',
    name: 'Reports',
    link: '/admin-panel/reports',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('reports_view'),
  },
  {
    icon: '/icons/design/Rows',
    name: 'Other Settings',
    link: '/admin-panel/other-settings',
    checkPermissions: (user) => !!user?.permissions && (user.permissions.includes('content_system_view') || user.permissions.includes('system_settings_view')),
  },
  {
    icon: '/icons/office/FileText',
    name: 'Orders',
    link: '/admin-panel/orders',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('orders_view'),
  },
  {
    icon: '/icons/office/FileText',
    name: 'Offers',
    link: '/admin-panel/offers',
    checkPermissions: (user) => !!user?.permissions && user.permissions.includes('offers_view'),
  },
  {
    icon: '/icons/communications/ChatText',
    name: 'Messages',
    link: '/admin-panel/messages',
    checkPermissions: (user) => user?.role === 'super_admin' || user.permissions.includes('messages_view'),
  },
];

export const CMS_PAGES: CMSPageIds[] = [
  'homepage',
  'how-it-works',
  'privacy-policy',
  'terms-of-use',
  'ambassadors',
  'become-ambassador',
  'affiliates',
  'onboarding',
];

export const SETTING_PAGES: SettingPage[] = ['app-fee', 'stripe-custom-flow', 'notifier-notifications', 'charity', '2fa-login', 'min-offer-price'];

export const SETTINGS_TITLES_MAP: Record<SettingPage, string> = {
  'stripe-custom-flow': 'Stripe Custom Flow',
  'app-fee': 'Fee',
  'notifier-notifications': 'Notifier',
  'charity': 'Charity',
  '2fa-login': '2FA Login',
  'min-offer-price': 'Min Offer Price',
}

export const CMS_PAGE_TITLE_MAP: MappableObject<any> = {
  homepage: {
    heading: 'Homepage',
    title: 'Other Settings - Homepage',
  },
  'privacy-policy': {
    heading: 'Privacy Policy',
    title: 'Other Settings - Privacy Policy',
  },
  'terms-of-use': {
    heading: 'Terms of Use',
    title: 'Other Settings - Terms of Use',
  },
  'how-it-works': {
    heading: 'How it Works',
    title: 'Other Settings - How it Works',
  },
  ambassadors: {
    heading: 'Ambassadors',
    title: 'Other Settings - Ambassadors',
  },
  affiliates: {
    heading: 'Affiliate Program',
    title: 'Other Settings - Affiliate Program',
  },
  'become-ambassador': {
    heading: 'Become an Ambassador',
    title: 'Other Settings - Become an Ambassador',
  },
  onboarding: {
    heading: 'Onboarding',
    title: 'Other Settings - Onboarding',
  },
};
