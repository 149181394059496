export const SIGN_IN_HEADER = 'Sign In to SENDY';
export const SIGN_IN_DESCRIPTION = 'Buy it, sell it, Send it. The peer to peer hub for buying selling and renting gear';
export const SIGN_UP_HEADER = 'Join the Community';
export const SIGN_UP_DESCRIPTION = 'Buy it, sell it, Send it. The peer to peer hub for buying selling and renting gear';
export const RESET_PASSWORD_HEADER = 'Reset your password';
export const RESET_PASSWORD_DESCRIPTION =
  'We will send you an email with instructions to reset your password. Check it in 1 min';
export const RESET_PASSWORD_SENT_HEADER = 'Reset Password Email Sent';
export const RESET_PASSWORD_SENT_DESCRIPTION =
  'An email with instructions has been sent to your email address registered with SENDY.';
export const RESET_PHONE_HEADER = 'Enter new Phone Number';
export const RESET_PHONE_DESCRIPTION = 'You will receive an SMS with 6 digit code to verify your phone number.';
export const SET_NEW_PASSWORD_DESCRIPTION = 'In order to protect your account make sure, your password';
export const SET_NEW_PASSWORD_REQUIREMENTS = [
  'Password must contain numbers;',
  'Password must contain uppercase letters;',
  'Password must have at least one @#$ symbol;',
  'Length must be greater than 8 characters;',
  'Password must contain lowercase letters',
];
export const AGREEMENT_HEADER = 'Review Terms and Conditions';
export const AGREEMENT_DESCRIPTION =
  'To join the SENDY community, buy, sell, and rent adventure sports gear from the marketplace, you must agree to the terms and conditions and privacy policy';

// error notifications
export const WRONG_EMAIL_NOTIFICATION = 'Please, enter a valid email address';
export const EMPTY_CODE_VERIFICATION_INPUT = 'Please, enter correct values';
export const ERROR_SIZE_IMAGE = 'Image weight should be up to 5MB';
export const HEIF_CONVERSION_WARNING = 'Image size can increase after conversion from .heic/.heif to .jpg format';
export const DONT_MATCH_PASSWORD_ERROR = 'Password and Confirm Password don’t match';
export const WRONG_PRICE_NOTIFICATION = 'Please, enter a valid price';
export const WRONG_OFFER_PRICE_BASE = 'The requested price must be at least';
export const WRONG_PRICE_LIMIT_NOTIFICATION = 'Price should be from $0 to $999 999';
export const WRONG_SOLID_PRICE_NOTIFICATION = 'Price should be whole number, it must not have dots';
export const WRONG_SUM_NOTIFICATION = 'Please, enter a valid sum';
export const WRONG_HEIGHT_INPUT = "Height must be in range from 1'0 to 9'0";
export const WRONG_WEIGHT_INPUT = 'Weight must be in range from 1 to 1000';
export const ERROR_LENGTH_PASSWORD = 'Password length should be from 8 to 48 symbols';
export const EMPTY_FIELD_NOTIFICATION = 'This field is required';
export const EMPTY_RATE_NOTIFICATION = 'You have to rate first';
export const TO_EASY_PASSWORD_NOTIFICATION =
  'Password should contain at least one uppercase letter, one lowercase letter, one number, and one special symbol';
export const PASSWORD_CONFIRMATION_ERROR_NOTIFICATION = 'Please, enter a valid confirmation';
export const AGREEMENT_ERROR_MESSAGE = 'To proceed you should agree';
export const WRONG_PHONE_NOTIFICATION = 'Please, enter a valid phone number';
export const WRONG_SANITIZED_TEXT_NOTIFICATION = 'Only Latin letters, numbers, dashes, commas, and spaces are allowed';
export const WRONG_TAG_NOTIFICATION = 'Only Latin letters are allowed';
export const WRONG_NAME_NOTIFICATION = 'Only Latin letters, apostrophes, dashes, and spaces are allowed';
export const WRONG_PLAIN_TEXT_NOTIFICATION =
  'Only Latin letters, numbers, dots, slashes, commas, apostrophes, dashes, and spaces are allowed';
export const WRONG_SIZE_NOTIFICATION =
  'Only Latin letters, apostrophes, dashes, dots, numbers, slashes, and spaces are allowed';
export const WRONG_QUESTION_NOTIFICATION =
  'Only Latin letters, numbers, dots, slashes, commas, dashes, questionnaires and spaces are allowed';
export const WRONG_USER_NAME_NOTIFICATION = 'Only Latin letters, numbers, dashes, dots, and underscores are allowed';
export const EMPTY_GENDER_NOTIFICATION = 'Please, select a gender';
export const EMPTY_CURRENCY_NOTIFICATION = 'Please, select a currency';
export const EMPTY_TYPE_NOTIFICATION = 'Please, select a type';
export const EMPTY_CATEGORY_NOTIFICATION = 'Please, select a category';
export const EMPTY_BRAND_NOTIFICATION = 'Please, select a brand';
export const EMPTY_SIZE_NOTIFICATION = 'Please, select a size';
export const EMPTY_WEIGHT_NOTIFICATION = 'Please, enter your weight';
export const EMPTY_HEIGHT_NOTIFICATION = 'Please, enter your height';
export const INVALID_DIMENSION_NOTIFICATION = 'Only dots, and numbers are allowed';
export const WRONG_LOCATION_NOTIFICATION = 'Please, enter your location';
export const WRONG_NUMBER_NOTIFICATION = 'Please, enter number';
export const WRONG_MULTISELECT_MARKETS = 'Please, you must choose at least one item ';
export const WRONG_PERCENTAGE_NOTIFICATION = 'Value must be from 0 to 100%.';
export const WRONG_ASCII_TEXT_NOTIFICATION = 'Only Latin letters, numbers, and ASCII symbols are allowed';
export const TO_LOW_BALANCE_NOTIFICATION = 'Your current balance is too low';
export const TO_MANY_ACTIVITIES_NOTIFICATION = 'You may select a maximum of 3 values.';
export const WRONG_HSC_NOTIFICATION = 'Please, enter a valid Harmonized System Code';

export const COPIED_SUCCESSFULLY = 'Successfully copied';
export const VALIDATION_PASSED = '';

export const DEFAULT_3DS_ERROR_MESSAGE =
  'Oops! Something went wrong while processing your payment. Please try again later or consider using an alternative payment method.';
