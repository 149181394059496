import {
  COMPARING_FIELDS_MAP,
  EMAIL_REGEXP,
  NAME_REGEX,
  PASSWORD_REGEX,
  USER_NAME_REGEX,
  PLAIN_TEXT_REGEX,
  SANITIZED_TEXT_REGEX,
  QUESTION_TEXT_REGEX,
  SIZE_REGEX,
  DIMENSION_REGEX,
  ASCII_REGEX,
  DIGIT_REGEX,
  LATIN_LETTERS__REGEX,
  PRICE_REGEX,
  TO_EASY_PASSWORD_NOTIFICATION,
  WRONG_NAME_NOTIFICATION,
  EMPTY_FIELD_NOTIFICATION,
  WRONG_WEIGHT_INPUT,
  WRONG_HEIGHT_INPUT,
  WRONG_EMAIL_NOTIFICATION,
  WRONG_PRICE_NOTIFICATION,
  EMPTY_HEIGHT_NOTIFICATION,
  EMPTY_CODE_VERIFICATION_INPUT,
  EMPTY_WEIGHT_NOTIFICATION,
  DONT_MATCH_PASSWORD_ERROR,
  ERROR_LENGTH_PASSWORD,
  WRONG_USER_NAME_NOTIFICATION,
  WRONG_SUM_NOTIFICATION,
  WRONG_PLAIN_TEXT_NOTIFICATION,
  WRONG_SANITIZED_TEXT_NOTIFICATION,
  WRONG_QUESTION_NOTIFICATION,
  WRONG_SIZE_NOTIFICATION,
  WRONG_SOLID_PRICE_NOTIFICATION,
  WRONG_PRICE_LIMIT_NOTIFICATION,
  WRONG_NUMBER_NOTIFICATION,
  INVALID_DIMENSION_NOTIFICATION,
  WRONG_PERCENTAGE_NOTIFICATION,
  WRONG_OFFER_PRICE_BASE,
  WRONG_TAG_NOTIFICATION,
  WRONG_ASCII_TEXT_NOTIFICATION,
  VALIDATION_PASSED,
  TO_LOW_BALANCE_NOTIFICATION,
  validatorMap,
  NUMERIC_TEXT_REGEX,
  TO_MANY_ACTIVITIES_NOTIFICATION, HSC_REGEXP, WRONG_HSC_NOTIFICATION,
} from 'consts';
import { JoinedFormFieldProps, ValidateType } from 'types';
import { removeHtmlTagFromString } from './formatters';
import {
  getFieldLimitNotification,
  getFirstSymbolNotification,
  getFromToFieldNotification,
  getInvalidFieldNotification,
  getMaxValueLengthNotification,
  getSymbolLimitNotification,
  getTagTitleLimitNotification,
} from './wordings';

const firstLetterUpRules: Array<string> = ['First Name', 'Last Name', 'Full Name'];

export const isValidSanitizedText = (value: string): boolean => !!String(value).match(SANITIZED_TEXT_REGEX);
export const isValidName = (value: string): boolean => !!String(value).match(NAME_REGEX);
export const isPlainText = (value: string): boolean => !!String(value).match(PLAIN_TEXT_REGEX);
export const isValidSize = (value: string): boolean => !!String(value).match(SIZE_REGEX);
export const isValidDimension = (value: string): boolean => !!String(value).match(DIMENSION_REGEX);
export const isValidQuestionText = (value: string): boolean => !!String(value).match(QUESTION_TEXT_REGEX);
export const isAsciiText = (value: string | number): boolean => !!String(value).match(ASCII_REGEX);
export const isNumber = (value: string | number): boolean => !!String(value).match(DIGIT_REGEX);
export const isLatinLetters = (value: string | number): boolean => !!String(value).match(LATIN_LETTERS__REGEX);
export const isPrice = (value: string | number): boolean => !!String(value).match(PRICE_REGEX);
export const isDimension = (value: string | number): boolean => !!String(value).match(NUMERIC_TEXT_REGEX);

export const getEmailErrorMessage: ValidateType = (value) => {
  if (!String(value).toLowerCase().match(EMAIL_REGEXP)) {
    return WRONG_EMAIL_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getSanitizedTextError = (value: string): string => {
  if (!isValidSanitizedText(value)) {
    return WRONG_SANITIZED_TEXT_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getPlainTextErrorMessage = (value: string): string => {
  if (!isPlainText(value)) {
    return WRONG_PLAIN_TEXT_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getQuestionErrorMessage = (value: string): string => {
  if (!isValidQuestionText(value)) {
    return WRONG_QUESTION_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getQuillErrorMessage: ValidateType = (value) => {
  if (removeHtmlTagFromString(value).length === 0 && !value.includes('<img')) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getEmptyFieldErrorMessage: ValidateType = (value, prefix, to) => {
  if (!value || value.length === 0) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (to && value.length > to) {
    return getFieldLimitNotification(1, to);
  }
  return VALIDATION_PASSED;
};

export const getTagErrorMessage: ValidateType = (value, to) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (to && value.length > to) {
    return getTagTitleLimitNotification(to);
  }
  if (!isLatinLetters(value)) {
    return WRONG_TAG_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getConfirmPasswordErrorMessage: ValidateType = (value, isConfirm) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (!isConfirm) {
    return DONT_MATCH_PASSWORD_ERROR;
  }
  return VALIDATION_PASSED;
};

export const getProperNameErrorMessage: ValidateType = (value, prefix, to) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (to && value.length > to) {
    return getSymbolLimitNotification(1, to);
  }
  if (!isLatinLetters(value) && firstLetterUpRules.includes(prefix)) {
    return getFirstSymbolNotification(prefix);
  }
  if (!isValidName(value)) {
    return WRONG_NAME_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getProperSizeErrorMessage: ValidateType = (value, prefix, to) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (to && value.length > to) {
    return getSymbolLimitNotification(1, to);
  }
  if (!isValidSize(value)) {
    return WRONG_SIZE_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getProperDimensionErrorMessage: ValidateType = (value, prefix, to) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (to && value.length > to) {
    return getSymbolLimitNotification(1, to);
  }
  if (!isValidDimension(value)) {
    return INVALID_DIMENSION_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getNotRequiredFieldLimitErrorMessage: ValidateType = (value, to) => {
  if (value && value.length > to) {
    return getMaxValueLengthNotification(to);
  }
  return VALIDATION_PASSED;
};

export const getTextAreaErrorMessage: ValidateType = (value, from = 0, to = 10000, required = true) => {
  if (!value && required) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (String(value).length < from || String(value).length > to) {
    return getFieldLimitNotification(from, to);
  }
  return VALIDATION_PASSED;
};

export const getUserNameErrorMessage: ValidateType = (value, prefix) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (value.length < 2) {
    return `${prefix} should be two symbols or longer.`;
  }
  if (!String(value).match(USER_NAME_REGEX)) {
    return WRONG_USER_NAME_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getLocationsErrorMessage: ValidateType = (value, prefix, to) => {
  if (!value && prefix !== 'Apartment') {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (prefix === 'Apartment' && value.length > to) {
    return prefix + ' field length should be up to ' + to + ' symbols';
  }
  return VALIDATION_PASSED;
};

export const getPasswordErrorMessage: ValidateType = (value) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (value.length > 48 || value.length < 8) {
    return ERROR_LENGTH_PASSWORD;
  }
  if (!String(value).match(PASSWORD_REGEX)) {
    return TO_EASY_PASSWORD_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getEmptyPasswordErrorMessage: ValidateType = (value) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getCodeVerificationErrorMessage: ValidateType = (value: string[], maxlength) => {
  if (value.length < maxlength) {
    return EMPTY_CODE_VERIFICATION_INPUT;
  }
  return VALIDATION_PASSED;
};

export const getPriceErrorMessage: ValidateType = (value, placeholder?: string) => {
  if (!isPrice(value)) {
    return placeholder ? getInvalidFieldNotification(placeholder) : WRONG_PRICE_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getOfferErrorMessage: ValidateType = (value: string, minValue: number) => {
  if (!isPrice(value)) {
    return WRONG_PRICE_NOTIFICATION;
  }
  if (+value < minValue) {
    return `${WRONG_OFFER_PRICE_BASE} $${minValue}`;
  }
  return VALIDATION_PASSED;
};

export const getSolidPriceErrorMessage: ValidateType = (value, minValue: number|undefined, maxValue: number|undefined) => {
  if (maxValue === undefined) {
    maxValue = minValue || 999999;
    minValue = 0;
  } else {
    minValue = minValue || 0;
  }

  if (+value < minValue || +value > maxValue) {
    return WRONG_PRICE_LIMIT_NOTIFICATION;
  }
  if (!isNumber(value)) {
    return WRONG_SOLID_PRICE_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getDimensionErrorMessage: ValidateType = (value, placeholder = 'value') => {
  if (!isDimension(value)) {
    return getInvalidFieldNotification(placeholder);
  }
  return VALIDATION_PASSED;
};

export const getHeightErrorMessage: ValidateType = (value, from = 1, to = 9) => {
  if (value && (+value > to || +value < from)) {
    return WRONG_HEIGHT_INPUT;
  }
  if (!isDimension(value)) {
    return EMPTY_HEIGHT_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getWeightErrorMessage: ValidateType = (value, from = 1, to = 1000) => {
  if (value && (+value > to || +value < from)) {
    return WRONG_WEIGHT_INPUT;
  }
  if (!isDimension(value)) {
    return EMPTY_WEIGHT_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

// todo check if it can be refactored
export const getComparingFieldValue = (name: string) => {
  if (typeof document !== 'object') {
    return;
  }
  // @ts-ignore
  return document.querySelector(`input[name=${COMPARING_FIELDS_MAP[name]}]`)?.value;
};

export const isBrowser = () => {
  return typeof window !== 'undefined';
};

export const getSimpleLengthErrorMessage = (
  value: string = '',
  from: string | number = 0,
  to: string | number = 200,
  customErrorMessage?: string,
) => {
  if (value.length < from || value.length > to) {
    return customErrorMessage || `Text length should be from ${from} to ${to} symbols`;
  }
  return VALIDATION_PASSED;
};

export const getHSCErrorMessage: ValidateType = (value) => {
  if (!String(value).toLowerCase().match(HSC_REGEXP)) {
    return WRONG_HSC_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getNumberRangeErrorMessage = (
  value: string,
  from: number = 0,
  to: number,
  customErrorMessage?: string,
  placeholder?: string,
) => {
  if (!isNumber(value)) {
    return WRONG_NUMBER_NOTIFICATION;
  }
  if (+value < from || +value > to) {
    return customErrorMessage || getFromToFieldNotification(placeholder, from, to);
  }
  return VALIDATION_PASSED;
};

export const getIncorrectBalanceMessage = (
  value: string | number,
  minValue: number = 0,
  maxValue: number = 999999,
  required?: boolean,
): string => {
  if (!required && !value) {
    return VALIDATION_PASSED;
  }
  if (required && !value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (getPriceErrorMessage(value)) {
    return WRONG_SUM_NOTIFICATION;
  }
  if (+value > +maxValue) {
    return TO_LOW_BALANCE_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getASCIIErrorMessage: ValidateType = (value: string, prefix, to, from) => {
  if (!value) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if ((to && value.length > to) || (from && value.length < from)) {
    return getSymbolLimitNotification(from || 1, to);
  }
  if (!isAsciiText(value)) {
    return WRONG_ASCII_TEXT_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const getPercentageErrorMessage = (value: string) => {
  const sanitizedValue = String(value).replace('%', '');
  if (!sanitizedValue) {
    return EMPTY_FIELD_NOTIFICATION;
  }
  if (!isNumber(sanitizedValue)) {
    return WRONG_NUMBER_NOTIFICATION;
  }
  if (+sanitizedValue < 0 || +sanitizedValue > 100) {
    return WRONG_PERCENTAGE_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};

export const handleNestedFieldValidators = (fields?: JoinedFormFieldProps[]) => {
  if (!fields) {
    return undefined;
  }

  return fields.map((field) => {
    field.fields && (field.fields = handleNestedFieldValidators(field.fields));
    return {
      ...field,
      validator:
        typeof field.validator === 'string' ? (field.validator = validatorMap[field.validator]) : field.validator,
    };
  });
};

export const productActivityValidation = (value: Array<string | number>) => {
  if (value?.length > 3) {
    return TO_MANY_ACTIVITIES_NOTIFICATION;
  }
  return VALIDATION_PASSED;
};
